import React, { useEffect, useState } from "react";

// styles
import GlobalStyles from "./assets/styles/global";
import "./App.css";

// components
import Header from "./Components/Header/header";
import Intro from "./Components/Intro/intro";
import Technology from "./Components/Technology/technology";
import Formats from "./Components/Formats/formats";
import Compare from "./Components/Compare/compare";
import WhyTry from "./Components/WhyTry/whytry";
import ECommerce from "./Components/ECommerce/ecommerce";
import Future from "./Components/Future/future";
import Footer from "./Components/Footer/footer";
import MyModal from "./Components/UI/MyModal";
import Modal from "react-modal";

const customStyles = {
  overlay: { backgroundColor: "rgba(255, 255, 255, 0.15)" },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

const App = () => {
  // Scroll Listener
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = (event) => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    onSubscribe();

    return () => offSubscribe();
  }, []);

  // Resize Listener
  const breakpoint = 650;
  const breakpoint_tablet = 1100;

  const [widthWindow, setWidthWindow] = useState(window.screen.width);

  const onSubscribe = () => window.addEventListener("resize", windowResize);
  const offSubscribe = () => window.removeEventListener("resize", windowResize);

  const windowResize = () => {
    setWidthWindow(window.innerWidth);
  };

  useEffect(() => {
    if (widthWindow <= breakpoint) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    if (widthWindow <= breakpoint_tablet && widthWindow > breakpoint) {
      setIsTablet(true);
    } else {
      setIsTablet(false);
    }
  }, [widthWindow]);

  const [language, setLanguage] = useState(
    localStorage.getItem("Language") ? localStorage.getItem("Language") : "Ru"
  );

  const [open, setOpen] = useState(false);

  return (
    <>
      <Modal
        isOpen={open}
        style={customStyles}
        onRequestClose={() => setOpen(false)}
        closeTimeoutMS={300}
      >
        <MyModal open={open} setOpen={setOpen} />
      </Modal>
      <Header
        changeLng={setLanguage}
        language={language}
        isMobile={isMobile}
        isTablet={isTablet}
        scrollY={scrollY}
      />
      <Intro language={language} isMobile={isMobile} />
      <Technology language={language} isMobile={isMobile} />
      <Formats language={language} isMobile={isMobile} setOpen={setOpen} />
      <Compare language={language} isMobile={isMobile} />
      <WhyTry language={language} isMobile={isMobile} setOpen={setOpen} />
      <ECommerce language={language} isMobile={isMobile} setOpen={setOpen} />
      <Future language={language} isMobile={isMobile} />
      <Footer language={language} isMobile={isMobile} setOpen={setOpen} />
      <GlobalStyles />
    </>
  );
};

export default App;
