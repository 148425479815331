import styled from "styled-components";
import { css } from "styled-components";
import { useEffect, useState } from "react";

import { A, Text, Title3 } from "../../assets/styles/component";

import useForm from "../../Composables/useForm";
import useModal from "../../Composables/useModal";
import Modal from "./Modal";

export const SModal = styled.div`
  position: fixed;

  position: fixed;
  top: calc(50% - 25px); // half of width
  left: calc(50% - 50px); // half of height
  display: flex;
  flex-direction: column;

  background: #ffffff;
  box-sizing: border-box;
  padding: 50px 40px;
  border-radius: 43px;
  width: 80vw;
  max-width: 516px;
  min-width: 320px;

  @media (max-width: 950px) {
    padding: 30px 20px;
    border-radius: 20px;
    gap: 12px;
  }
`;

const SForm = styled.form`
  ${({ success = false, fail = false, loading = false }) =>
    [success, fail, loading].some((submitState) => submitState === true)
      ? `display: none;`
      : `display: flex;`}
  flex-direction: column;
  gap: 4.875rem;
  width: 100%;

  & button {
    background: radial-gradient(
        105.43% 294.48% at -10.2% -116.67%,
        #376ce0 0%,
        #2485dd 25.96%,
        #4f4ed9 74.99%,
        #7320cf 96.87%
      ),
      #2850bf;
    max-width: 309px;
    box-shadow: inset 0px 0px 37px -11px rgba(255, 255, 255, 0.29);
    border-radius: 7.813rem;
    width: 100%;
    padding: 30px 71px;
  }
`;

const FormBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2.188rem;

  width: 100%;

  text-align: left;
  z-index: 2;
`;

const Div = css`
  display: flex;
  flex-direction: column;
`;

export const TitleBox = styled.div`
  ${Div}
  gap: 1.563rem;

  @media (max-width: 500px) {
    max-width: 14.188rem;
  }
`;

export const Title = styled(Title3)`
  font-weight: 400;
  font-size: clamp(1.5rem, 4vw, 2.375rem);
  line-height: 110%;

  background: linear-gradient(90.45deg, #116ad0 1.65%, #00aee8 114.47%), #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media (max-width: 500px) {
    max-width: 14.063rem;
  }

  @media (max-width: 950px) {
    margin-bottom: 10px;
  }
`;

const InputsBox = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 0.875rem;

  width: 100%;

  & svg,
  & g,
  & path {
    position: absolute;
    fill: #3c3c3c;
    opacity: 0.5;
    top: 6.5rem;
    left: 190px;
  }

  @media (max-width: 950px) {
    svg,
    g,
    path {
      top: 5rem;
      width: 20px;
    }
  }
`;

const Fields = css`
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #cceffa;
  border-radius: 15px;

  max-width: 500px;
  height: 4.375rem;

  padding-left: 1.5rem;
  outline: none;
  box-sizing: border-box;

  &::placeholder,
  & {
    font-family: "HeliosExtC", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
    color: #9d9d9d;
  }

  &:focus {
    outline: 2px solid rgba(255, 255, 255, 0.3);
  }

  @media (max-width: 950px) {
    height: 3.375rem;
  }
`;

const Input = styled.input`
  ${Fields}
  width: 100%;
`;

const TextAreaBox = styled.div`
  position: relative;
  width: 100%;

  & p {
    position: absolute;
    top: 1.625rem;
    left: 159px;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
    color: #cecece;
  }

  @media (max-width: 950px) {
    p {
      top: 1.4rem;
      font-size: 0.6rem;
    }
  }
`;

const Textarea = styled.textarea`
  ${Fields}
  width: 100%;
  height: 212px;
  padding-top: 1.563rem;

  resize: none;
  position: relative;

  @media (max-width: 950px) {
    padding-top: 1rem;
  }
`;

const Link = styled(A)`
  color: #3c3c3c;
  text-decoration: underline;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 160%;

  &:hover {
    color: #abe4f7;
  }
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;

  width: 100%;
  text-align: left;

  & p {
    max-width: 346px;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 160%;
    color: rgba(171, 171, 171, 0.6);

    a {
      margin: 5px;
      color: #ababab;
    }
  }

  & button {
    box-sizing: border-box;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.063rem;
  }

  @media (max-width: 950px) {
    button {
      height: 50px;
      font-size: 14px;
      width: 100%;
      padding: 0;
    }
  }
`;

const Button = styled.button`
  color: #fff;
  border: none;
`;

const submitResultStyles = `p {
    display: block;
    margin-top: 25px;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #3c3c3c;

    a {
      margin-left: 5px;
      color: #3c3c3c;
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }`;

export const Success = styled.div`
  ${({ success = false }) =>
    success == false ? `display: none;` : `display: block;`}

  ${submitResultStyles}
`;

export const Fail = styled.div`
  ${({ fail = false }) =>
    fail == false ? `display: none;` : `display: block;`}

  ${submitResultStyles}
`;

export const Loading = styled.div`
  ${({ loading = false }) =>
    loading == false ? `display: none;` : `display: block;`}
`;

export const Close = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(120%);
  border: 1px solid #ffffff55;
  border-radius: 200px;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.25s ease-in-out;
  z-index: 100;

  &:hover {
    border: 1px solid #ffffff88;
    transform: translateX(120%) scale(1.025);
  }

  @media (max-width: 950px) {
    transform: translateX(0);
    border: 2px solid #00000022;
    top: 5%;
    right: 5%;
    width: 30px;
    height: 30px;

    svg,
    path,
    g {
      width: 15px;
      height: 15px;
      stroke: #00000022;
      fill: #00000022;
    }

    &:hover {
      transform: scale(1.025);
    }
  }
`;

const MyModal = ({ open, setOpen }) => {
  const { loading, success, fail, onClose, onSubmit } = useModal();

  const [name, setName] = useState("");
  const {
    value,
    setValue,
    valueTextArea,
    setValueTextArea,
    createAndSubmitForm,
  } = useForm(onSubmit);

  useEffect(() => {
    if (!open) onClose(open);
  }, [open]);

  const sendRequest = async () => {
    if (name != "" && value != "") {
      await createAndSubmitForm({ name });
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      success={success}
      loading={loading}
      fail={fail}
    >
      <SForm
        onSubmit={(e) => {
          e.preventDefault();
          sendRequest();
        }}
        success={success}
        fail={fail}
        loading={loading}
      >
        <FormBox>
          <TitleBox>
            <Title>Оставьте контакты, чтобы мы могли с вами связаться</Title>
          </TitleBox>

          <InputsBox>
            <Input
              placeholder={"Ваше имя"}
              onFocus={(e) => {
                e.target.value == "" && setName(" ");
                e.target.selectionStart = 0;
                e.target.selectionEnd = 0;
              }}
              onBlur={(e) => {
                e.target.value == " " && setName("");
              }}
              onChange={(e) => setName(e.target.value)}
              value={name}
              important
            />

            <Input
              placeholder={"Ваш номер или ник в Telegram"}
              onFocus={(e) => {
                e.target.value == "" && setValue(" ");
                e.target.selectionStart = 0;
                e.target.selectionEnd = 0;
              }}
              onBlur={(e) => {
                e.target.value == " " && setValue("");
              }}
              onChange={(e) => setValue(e.target.value)}
              value={value}
              important
            />

            <TextAreaBox>
              {valueTextArea.length === 0 && <Text>(не обязательно)</Text>}

              <Textarea
                placeholder={"Опишите задачу"}
                onFocus={(e) => {
                  e.target.value == "" && setValueTextArea(" ");
                  e.target.selectionStart = 0;
                  e.target.selectionEnd = 0;
                }}
                onBlur={(e) => {
                  e.target.value == " " && setValueTextArea("");
                }}
                onChange={(e) => setValueTextArea(e.target.value)}
                value={valueTextArea}
              ></Textarea>
            </TextAreaBox>
          </InputsBox>

          <ButtonBox>
            <Button type="blue">Отправить заявку</Button>
            <Text size="12">
              Нажимая на кнопку, вы даете согласие на обработку
              <Link
                targer="_blank"
                href="/privacy"
                aria-label={"Прочитать политику обработки персональных данных"}
              >
                персональных данных
              </Link>
              и соглашаетесь c
              <Link
                targer="_blank"
                href="/terms"
                aria-label={"Прочитать политику конфиденциальности"}
              >
                политикой конфиденциальности
              </Link>
            </Text>
          </ButtonBox>
        </FormBox>
      </SForm>
    </Modal>
  );
};

export default MyModal;
