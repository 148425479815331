export default class FeedbackHelper {
  static createForm({ messenger, text, name }) {
    const form = new FormData();
    [
      ["phone", messenger],
    ].forEach(([key, value]) => {
      form.append(key, value);
    });
    form.append("text", `Описание задачи:${text}`);
    form.append("url", document.location.href.replace(/#(.*)|https?:\/\//g, '').replace(/\./g,' '));

    return form;
  }
}
