export const RusLoc = {
  header: {
    services: "",
    cases: "",
    aboutUs: "",
    blog: "",
  },
  intro: {
    title: "",
    tags: {
      telegram: "",
      vk: "",
      odnoclassniki: "",
      Video_Ads: "",
      Analytics: "",
      olv: "",
      TG_Ads: "",
      instream: "",
    },
  },
  about: {
    orbit: "",
    adsType: {
      messangers: {
        name: "",
        desc: "",
      },
      native: {
        name: "",
        desc: "",
      },
      video: {
        name: "",
        desc: "",
      },
    },
  },
  products: {
    title: "",
    products_boxes: [
      {
        title: "",
        proffessionals: "",
        name: "",
        desc_list: ["", "", "", ""],
      },
      {
        title: "",
        proffessionals: "",
        name: "",
        desc_list: ["", "", "", ""],
      },
      {
        title: "",
        proffessionals: "",
        name: "",
        desc_list: ["", "", "", ""],
      },
      {
        title: "",
        proffessionals: "",
        name: "",
        desc_list: ["", ""],
      },
      {
        title: "",
        proffessionals: "",
        name: "",
        desc_list: ["", ""],
      },
    ],
  },
  tasks: {
    title: "",
    tasks_list: [
      { title: "", desc: "", tags: ["", ""] },
      { title: "", desc: "", tags: [""] },
      { title: "", desc: "", tags: [""] },
      { title: "", desc: "", tags: ["", "", ""] },
    ],
  },
  services: {
    title: "",
    services_list: [
      { title: "", desc: "" },
      { title: "", desc: "" },
    ],
  },
  partners: {
    partners_slider: {
      title: "",
      toggler: ["", ""],
    },
    cases: {
      title: "",
    },
  },
  posts: {
    title: "",
  },
  social_media: {
    title: "",
    form: {
      title: "",
      p: "",
      input: "",
      textarea: "",
      textarea_after: "",
      placeholders: ["", ""],
      policy: "",
    },
  },
  footer: {
    title: "",
    desc_guest: "",
    adress: "",
    nav: ["", "", "", "", "", "", ""],
    adress_company: "",
    policy: "",
    terms: "",
    u_agreement: "",
  },
  buttons: {
    request: "",
    video: "",
    login: "",
    more: "",
    blog: "",
    send: "",
    map: "",
  },
};

export const EngLoc = {
  header: {
    services: "Services",
    cases: "Cases",
    aboutUs: "About Us",
    blog: "Blog",
    contacts: "Contacts",
  },
  intro: {
    title: "Technological solutions for your",
    title_span: "marketing",
    tags: {
      telegram: "Telegram",
      vk: "Vkontakte",
      odnoclassniki: "Odnoclassniki",
      Video_Ads: "Video-ads",
      Analytics: "Analytics",
      olv: "Ovl",
      TG_Ads: "TG Ads",
      instream: "Instream",
    },
  },
  about: {
    orbit:
      "Since 2015, we have been one of the largest providers of traffic in social networks and messengers. We provide service, analytics and support",
    adsType: {
      messangers: {
        name: "Advertising in messengers",
        desc: "Automation of processes from the selection of sources, to placements and post-analysis",
      },
      native: {
        name: "Native advertising on social networks",
        desc: "Automation of processes from the selection of sources, to placements and post-analysis",
      },
      video: {
        name: "Video advertising on websites",
        desc: "Placement and promotion of your videos with coverage of more than 25 m impressions per day",
      },
    },
  },
  products: {
    title: "Our products",
    products_boxes: [
      {
        title:
          "The largest analytical and advertising platform for marketing and business",
        proffessionals: "For market professionals",
        desc_list: [
          "More than 300,000 sources in all social networks and messengers",
          "Deep analytics on more than 15 parameters",
          "Requests for collections and ready-made collections",
          "Launching the RC in a few clicks",
        ],
      },
      {
        title:
          "The largest analytical and advertising platform for marketing and business",
        desc_list: [
          "Launch of official advertising in TG ADS",
          "Placement in any telegram channels",
          "More than 10,000 connected publishers",
          "Full analytics of the advertising campaign",
        ],
      },
      {
        title: "A platform for placing video ads on news and content sites",
        desc_list: [
          "More than 300,000 sources in all social networks and messengers",
          "Deep analytics on more than 10 click parameters",
        ],
      },
      {
        title: "A platform for placing video ads on news and content sites",
        desc_list: [
          "More than 300,000 sources in all social networks and messengers",
          "Deep analytics on more than 10 click parameters",
        ],
      },
    ],
  },
  tasks: {
    title: "What tasks are solving",
    tasks_list: [
      {
        title: "Display advertising",
        desc: "Bringing the brand to the market, increasing awareness, etc",
      },
      {
        title: "Conversions",
        desc: "Achieving goals on websites and sales",
      },
      {
        title: "Promotion of social networks business",
        desc: "Increase in the number of subscribers in all social networks",
      },
      {
        title: "Analytics",
        desc: "Analysis of tariff sources and advertising campaigns",
      },
    ],
  },
  services: {
    title: "How we work",
    services_list: [
      {
        title: "Full-service",
        desc: "A platform for getting leads from all sources with pay-per-action. Everything is included and more text for an example",
      },
      {
        title: "Self-service",
        desc: "A platform for getting leads from all sources with pay-per-action. Everything is included and more text for an example",
      },
    ],
  },
  partners: {
    partners_slider: {
      title: "They work with us",
      toggler: ["Advertising agencies", "Brands"],
    },
    cases: {
      title: "Cases",
      period: "Period",
      subscribers: "Subscribers",
      views: "Views",
      coverage: "Coverage",
      cases_list: [
        "BCS presented its services and the opportunity to invest in broker products to multiply its funds",
        "Advertising a challenge for moviegoers from the online cinema Okko",
        "The advertising campaign for the Skolkovo Business School in Telegram is the promotion of a master class from Tom Rowlands. As a result: sold-out",
        "Placement in Telegram for Glamour - testing a new tool for a large glossy publication",
        "Advertising of the English language school to residents of Moscow with the help of crops on all SocialJet sites",
        "Sberbank announces the launch of the 500 Best Startups investment project",
      ],
    },
  },
  posts: {
    title: "Posts",
  },
  social_media: {
    title: "We are in the media",
    form: {
      title: "Do you want to launch an ad?",
      p: "Send a request and we will do everything beautifully",
      input: "Your number or messenger",
      textarea: "Describe the task",
      textarea_after: "(optional)",
      policy: "",
      checkboxes: ["Call me", "Write to me in Telegram"],
    },
  },
  footer: {
    title: "Come visit us",
    desc_guest:
      "We really like to get to know each other in life: we will treat you to a very tasty coffee or tea, feed you cakes and introduce you to the friendly SocialJet team :)",
    adress: "Moscow, 4th Lesnoy lane, building 4, office 4103",
    nav: [
      "Technology",
      "Cases",
      "Company",
      "Contacts",
      "Telegram Exchange",
      "The blog",
      "Publishers",
    ],
    adress_company:
      "123001, Moscow, Bolshaya Sadovaya str., building 5, building 1, office 515",
    policy: "Personal Data Processing Policy",
    terms: "Rules for the provision of services",
    u_agreement: "User agreement",
  },
  buttons: {
    request: "Quick request",
    video: "Watch the video",
    login: "Login",
    more: "More",
    blog: "Blog",
    send: "Send",
    map: "On the map",
  },
};
