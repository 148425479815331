import { useState } from "react";
import FeedbackHelper from "../Helpers/FeedbackHelper";

export default function useForm(callback) {
  const [value, setValue] = useState("");
  const [valueTextArea, setValueTextArea] = useState("");

  const createAndSubmitForm = async (fields) =>
    callback(
      FeedbackHelper.createForm({
        messenger: value,
        text: valueTextArea,
        ...fields,
      })
    );

  return {
    value,
    setValue,
    valueTextArea,
    setValueTextArea,
    createAndSubmitForm,
  };
}
