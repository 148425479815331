import React from "react";
import { EngLoc } from "../../assets/constants/localizations";

const HeaderDesktopLazy = React.lazy(() => import("./headerDesktop"));
const HeaderTabletLazy = React.lazy(() => import("./headerTablet"));
const HeaderMobileLazy = React.lazy(() => import("./headerMobile"));

const Header = ({ language, changeLng, isMobile, isTablet, scrollY }) => {
  const navItems = [
    {
      name: language === "Ru" ? "Сервисы" : EngLoc.header.services,
      href: "#services",
    },
    {
      name: language === "Ru" ? "Кейсы" : EngLoc.header.cases,
      href: "#cases",
    },
    {
      name: language === "Ru" ? "О нас" : EngLoc.header.aboutUs,
      href: "#about",
    },
    {
      name: language === "Ru" ? "Блог" : EngLoc.header.blog,
      href: "https://blog.socialjet.pro/",
      blank: true,
    },
    {
      name: language === "Ru" ? "Контакты" : EngLoc.header.contacts,
      href: "#contacts",
    },
  ];

  return (
    <>
      {isMobile ? (
        <HeaderMobileLazy
          changeLng={changeLng}
          navItems={navItems}
          language={language}
          scrollY={scrollY}
        />
      ) : isTablet ? (
        <HeaderTabletLazy
          changeLng={changeLng}
          navItems={navItems}
          language={language}
          scrollY={scrollY}
        />
      ) : (
        <HeaderDesktopLazy
          changeLng={changeLng}
          navItems={navItems}
          language={language}
          scrollY={scrollY}
        />
      )}
    </>
  );
};

export default Header;
