import { BLOG_API_URL, FEEDBACK_URL } from "../config";

export const sendFeedback = async (form) => {
  const url = FEEDBACK_URL;

  try {
    const response = await fetch(url, { method: "POST", body: form });
    const json = await response.json();
    return json;
  } catch (e) {
    throw new Error(e);
  }
};

export const fetchBlogApi = async (query) => {
  const url = `${BLOG_API_URL}/${query}`;

  try {
    const response = await fetch(url, { method: "GET" });

    return response;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
