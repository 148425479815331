import React from "react";

const CompareDesktopLazy = React.lazy(() => import("./compareDesktop"));
const CompareMobileLazy = React.lazy(() => import("./compareMobile"));

const Compare = ({ language, isMobile }) => {
  return (
    <>
      {isMobile ? (
        <CompareMobileLazy language={language} />
      ) : (
        <CompareDesktopLazy language={language} />
      )}
    </>
  );
};

export default Compare;
