import loader from "../../assets/images/loader.gif";

const LoaderHtml = `<div style="padding-top:1rem;padding-bottom:1rem;min-height:4rem;padding-left:1rem">
  <img alt="Загрузка ..." src="${loader}" />
</div>`;

export const LoaderJsx = () => (
  <div
    style={{
      paddingTop: "1rem",
      paddingBottom: "1rem",
      minHeight: "4rem",
      paddingLeft: "1rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <img alt="Загрузка ..." src={loader} />
  </div>
);

export default LoaderHtml;
