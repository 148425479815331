import React from "react";

const IntroDesktopLazy = React.lazy(() => import("./introDesktop"));
const IntroMobileLazy = React.lazy(() => import("./introMobile"));

const Intro = ({ language, isMobile }) => {
  return (
    <>
      {isMobile ? (
        <IntroMobileLazy language={language} />
      ) : (
        <IntroDesktopLazy language={language} />
      )}
    </>
  );
};

export default Intro;
