import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #ffffff22;
  }

  ::-webkit-scrollbar-thumb {
    background: #d9d9d95d;
    border-radius: 50px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #ffffff22;
  }

  html {
    scroll-behavior: smooth;
  }

  * {
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: rgba(0,0,0,0.05);
  }

  body {
    width: 100%;
  }

  ul,ol {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  #root {
    contain: paint;
  }

`;
