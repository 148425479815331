import React from "react";

const FooterDesktopLazy = React.lazy(() => import("./footerDesktop"));
const FooterMobileLazy = React.lazy(() => import("./footerMobile"));

export const links = {
  instagram: "https://www.instagram.com/socialjet.pro/",
  telegram: "https://t.me/socialjet",
  vk: "https://vk.com/socialjet",
  odnoklassniki: "https://ok.ru/socialjet.ru",
  yandexzen: "https://zen.yandex.ru/id/5cf9f815c3edd100b0f7daa4",
  youtube: "https://www.youtube.com/@socialjet3733",
};

export const FooterProjectLinks = () => (
  <>
    <a href="#cases">Кейсы</a>
    <a href="#about">Кампания</a>
    <a href="https://telejet.pro" target="_blank" rel="noreferrer">
      Биржа Telegram
    </a>
    <a href="https://blog.socialjet.pro/" target="_blank" rel="noreferrer">
      Блог
    </a>
    <a href="https://socialjet.pro/publishers" target="_blank" rel="noreferrer">
      Паблишерам
    </a>
  </>
);

const Footer = ({ language, isMobile, setOpen }) => {
  return (
    <>
      {isMobile ? (
        <FooterMobileLazy language={language} setOpen={setOpen} />
      ) : (
        <FooterDesktopLazy language={language} setOpen={setOpen} />
      )}
    </>
  );
};

export default Footer;
