import React from "react";

const EcommerceDesktopLazy = React.lazy(() => import("./ecommerceDesktop"));
const EcommerceMobileLazy = React.lazy(() => import("./ecommerceMobile"));

const Ecommerce = ({ language, isMobile, setOpen }) => {
  return (
    <>
      {isMobile ? (
        <EcommerceMobileLazy language={language} setOpen={setOpen} />
      ) : (
        <EcommerceDesktopLazy language={language} setOpen={setOpen} />
      )}
    </>
  );
};

export default Ecommerce;
