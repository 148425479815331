import styled from "styled-components";
import { css } from "styled-components";
import { Text, Title3 } from "../../assets/styles/component";
import { LoaderJsx } from "./Loader";

export const CloseSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <path
      d="M1 1L10.5 10.5M10.5 10.5L1 20M10.5 10.5L20 1M10.5 10.5L20 20"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
);

export const SModal = styled.div`
  display: flex;
  flex-direction: column;

  background: #ffffff;
  box-sizing: border-box;
  padding: 50px 40px;
  border-radius: 43px;
  width: 80vw;
  max-width: 516px;
  min-width: 320px;

  @media (max-width: 950px) {
    padding: 30px 20px;
    border-radius: 20px;
    gap: 12px;
  }
`;

const Div = css`
  display: flex;
  flex-direction: column;
`;

export const TitleBox = styled.div`
  ${Div}
  gap: 1.563rem;

  @media (max-width: 500px) {
    max-width: 14.188rem;
  }
`;

export const Title = styled(Title3)`
  font-weight: 400;
  font-size: clamp(1.5rem, 4vw, 2.375rem);
  line-height: 110%;

  background: linear-gradient(90.45deg, #116ad0 1.65%, #00aee8 114.47%), #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media (max-width: 500px) {
    max-width: 14.063rem;
  }

  @media (max-width: 950px) {
    margin-bottom: 10px;
  }
`;

const submitResultStyles = `p {
    display: block;
    margin-top: 25px;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #3c3c3c;

    a {
      margin-left: 5px;
      color: #3c3c3c;
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }`;

export const Success = styled.div`
  ${({ success = false }) =>
    success == false ? `display: none;` : `display: block;`}

  ${submitResultStyles}
`;

export const Fail = styled.div`
  ${({ fail = false }) =>
    fail == false ? `display: none;` : `display: block;`}

  ${submitResultStyles}
`;

export const Loading = styled.div`
  ${({ loading = false }) =>
    loading == false ? `display: none;` : `display: block;`}
`;

export const Close = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(120%);
  border: 1px solid #ffffff55;
  border-radius: 200px;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.25s ease-in-out;
  z-index: 100;

  &:hover {
    border: 1px solid #ffffff88;
    transform: translateX(120%) scale(1.025);
  }

  @media (max-width: 950px) {
    transform: translateX(0);
    border: 2px solid #00000022;
    top: 5%;
    right: 5%;
    width: 30px;
    height: 30px;

    svg,
    path,
    g {
      width: 15px;
      height: 15px;
      stroke: #00000022;
      fill: #00000022;
    }

    &:hover {
      transform: scale(1.025);
    }
  }
`;

const Modal = ({ open, setOpen, success, loading, fail, children }) => (
  <SModal
    onClick={(e) => {
      e.stopPropagation();
    }}
  >
    {children}
    <Close onClick={() => setOpen(false)}>
      <CloseSvg />
    </Close>
    <Success success={success}>
      <Title>
        Спасибо <br /> за вашу заявку!
      </Title>

      <Text>
        Мы свяжемся с вами в ближайшее время для обсуждения деталей, а пока
        можете
        <a rel="noreferrer" href="https://blog.socialjet.pro/" target="_blank">
          почитать наш блог
        </a>
      </Text>
    </Success>

    <Loading loading={loading}>
      <Title>Загрузка...</Title>

      <LoaderJsx />
    </Loading>

    <Fail fail={fail}>
      <Title>Произошла ошибка отправки формы</Title>

      <Text>
        К сожалению, ваше сообщение не доставлено. Проверьте заполнение полей и
        попробуйте еще раз.
        <br /> Если вы вновь видите ошибку, напишите нам в Telegram:
        @socialjet_bot .<br />А еще можно позвонить по телефону +7 (495) 768 58
        65 или написать нам в соцсети.
      </Text>
    </Fail>
  </SModal>
);

export default Modal;
