import styled from "styled-components";
import { css } from "styled-components";

export const Title1 = styled.h1`
  font-size: clamp(2.5rem, 5vw, 5.25rem);
  font-weight: ${({ weight = 400 }) => weight};
  line-height: 145%;
  color: ${({ color = "white" }) => color};
`;

export const Title2 = styled.h2`
  font-weight: 400;
  text-align: center;
  font-size: clamp(2.5rem, 4vw, 4rem);
  line-height: 120%;
  color: ${({ color = "white" }) => color};
`;

export const Title3 = styled.h3`
  font-weight: ${({ weight = 400 }) => weight};
  font-size: clamp(1.1rem, 3vw, 1.625rem);
  text-align: center;
  line-height: 140%;
  color: ${({ color = "white" }) => color};
`;

const textStandart = css`
  font-size: ${({ fix_size = 18, size = 18 }) =>
    fix_size ? fix_size + "px" : `clamp(${size - 5 >= 14 ? size - 5 : 14}px, 1vw,${size}px)`};
  font-weight: ${({ weight = 400 }) => weight};
  line-height: ${({ size = 18 }) => parseFloat(size) + parseFloat(size) / 2}px;
  color: ${({ color = "white" }) => color};
`;

export const Text = styled.p`
  ${textStandart}
`;

export const A = styled.a`
  ${textStandart}

  &:hover {
    cursor: pointer;
  }
`;

export const InnerWrap = styled.div`
  position: relative;
  max-width: 1600px;
  width: 90vw;
  margin: 0 auto;
`;
