import React from "react";

import AI from "../../assets/images/AI.svg";
import Analytic from "../../assets/images/Analytic.svg";
import List from "../../assets/images/List.svg";
import PostAnalityc from "../../assets/images/PostAnalytic.svg";
import Rating from "../../assets/images/Rating.svg";

const TechnologyDesktopLazy = React.lazy(() => import("./technologyDesktop"));
const TechnologyMobileLazy = React.lazy(() => import("./technologyMobile"));

const Technology = ({ language, isMobile }) => {
  const technologies = [
    {
      img: Analytic,
      title: "Аналитика аккаунтов",
      text: "Платформа предоставляет статистику по количеству подписчиков, интересам, полу, географии, а также отсеивает аккаунты с ботами и накрутками",
    },
    {
      img: AI,
      title: "Искусственный интелект",
      text: "Объединение технологии AR и AI позволяют создавать уникальный контент прямо на трансляции стримера",
    },
    {
      img: List,
      title: "Система подбора",
      text: "Находите блогеров и стримеров наиболее соответствующих вашей целевой аудитории и целям кампании",
    },
    {
      img: Rating,
      title: "Система рейтингов",
      text: "Узнавайте мнение других рекламодателей о сотрудничестве с выбранным исполнителем, а также оставляйте свои отзывы",
    },
    {
      img: PostAnalityc,
      title: "Постаналитика кампаний",
      text: "Отслеживайте эфективность рекламных кампаний с помощью детальной статистики. Проанализируйте количество кликов и отслеживайте влияние вашей РК на продажи",
    },
  ];
  return (
    <>
      {isMobile ? (
        <TechnologyMobileLazy technologies={technologies} language={language} />
      ) : (
        <TechnologyDesktopLazy
          technologies={technologies}
          language={language}
        />
      )}
    </>
  );
};

export default Technology;
