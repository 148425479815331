import React from "react";

import WhyTry_1 from "../../assets/images/WhyTry_1.svg";
import WhyTry_2 from "../../assets/images/WhyTry_2.svg";
import WhyTry_3 from "../../assets/images/WhyTry_3.svg";

const WhyTryDesktopLazy = React.lazy(() => import("./whytryDesktop"));
const WhyTryMobileLazy = React.lazy(() => import("./whytryMobile"));

const WhyTry = ({ language, isMobile, setOpen }) => {
  const whytrylist = [
    {
      img: WhyTry_1,
      text: "Тонкая настройка таргетинга и детальная отчетность по итогам кампании",
    },
    {
      img: WhyTry_2,
      text: "Анимация и AR-взаимодействие с блогером точно будут замечены и прокомментированы",
    },
    {
      img: WhyTry_3,
      text: "Встраиваем бренд и продукт прямо в поток и помогаем становиться заметнее ",
    },
  ];

  return (
    <>
      {isMobile ? (
        <WhyTryMobileLazy
          whytrylist={whytrylist}
          language={language}
          setOpen={setOpen}
        />
      ) : (
        <WhyTryDesktopLazy
          whytrylist={whytrylist}
          language={language}
          setOpen={setOpen}
        />
      )}
    </>
  );
};

export default WhyTry;
