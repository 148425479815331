import { useState } from "react";
import FeedbackService from "../Services/FeedbackService";

export default function useModal() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const animationDelay = 500;

  const onClose = () => {
    setTimeout(() => {
      setSuccess(false);
      setFail(false);
      setLoading(false);
    }, animationDelay);
  };

  const finishWithSuccess = () => {
    setLoading(false);
    setSuccess(true);
  };

  const finishWithFail = () => {
    setLoading(false);
    setFail(true);
  };

  const onResult = (result) => {
    const resultSuccess = result === 1;
    if (resultSuccess) {
      finishWithSuccess();
    } else {
      throw new Error("Не удалось отправить форму.");
    }
  };

  const onSubmit = async (form) => {
    setLoading(true);

    try {
      const result = await FeedbackService.submitForm(form);

      onResult(result);
    } catch (e) {
      finishWithFail();
      throw e;
    }
  };

  return {
    loading,
    setLoading,
    success,
    setSuccess,
    fail,
    setFail,
    onClose,
    onSubmit,
  };
}
