import React from "react";

const FormatsDesktopLazy = React.lazy(() => import("./formatsDesktop"));
const FormatsMobileLazy = React.lazy(() => import("./formatsMobile"));

const Formats = ({ language, isMobile, setOpen }) => {
  return (
    <>
      {isMobile ? (
        <FormatsMobileLazy language={language} setOpen={setOpen} />
      ) : (
        <FormatsDesktopLazy language={language} setOpen={setOpen} />
      )}
    </>
  );
};

export default Formats;
