import React from "react";

const FutureDesktopLazy = React.lazy(() => import("./futureDesktop"));
const FutureMobileLazy = React.lazy(() => import("./futureMobile"));

const Future = ({ language, isMobile }) => {
  return (
    <>
      {isMobile ? (
        <FutureMobileLazy language={language} />
      ) : (
        <FutureDesktopLazy language={language} />
      )}
    </>
  );
};

export default Future;
